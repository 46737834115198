/* Provide sufficient contrast against white background */
body {
  background-color: #f0ece1;
}

#footer {
  min-height: 3em;
  margin-top: 2em;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.loader {
  width: 100%;
  height: 100vh;
}

.errorBox {
  border: 1px solid #FF0000;
  border-radius: 6px;
  background-color: #FF000033;
  padding: 0.5em;
  margin-bottom: 1em;
}

.serviceType {
  background-color: #d7cbaf;
}

.serviceType h5 {
  margin: 0;
}

.serviceProvider {
  min-height: 2em;
}

.serviceProvider:not(:last-child) {
  border-bottom: 1px solid #d7cbaf;
}

.serviceProviderCard {
  background-color: transparent;
  border: none;
  padding: 0;
}

.serviceProviderCard:hover {
  background-color: #f5f5f5;
}

.inactive.serviceProviderCard {
  background-color: #FF444439;
}

.inactiveLabel {
  color: #ff0000;
  font-weight: bolder;
}

.contactLabel {
  font-size: x-small;
  font-weight: bold;
  margin-bottom: -5px;
}

.serviceProviderList .serviceProviderDescription {
  --line-height: 1.5em;
  --max-lines: 6;
  line-height: var(--line-height);
  max-height: calc(var(--line-height) * var(--max-lines));
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: var(--max-lines);
  -webkit-box-orient: vertical;
}

.desktop {
  display: unset;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 992px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: unset;
  }

  .serviceProviderCard {
    background-color: #ffffff;
    padding: 1em;
  }

  .serviceProviderCard .card-body {
    padding: 1em !important;
  }

  .serviceProvider:nth-child(n+1) {
    border-bottom: none;
  }

  .serviceProviderList .serviceProviderUrl {
    margin-top: 1em;
    text-align: right;
  }

  .contactLabel {
    font-size: smaller;
  }

  .contactLabel:first-child() {
    padding-top: 1em;
  }
}